<template>
    <dashboard-layout>
        <div class="content-area__body">
            <section class="pipeline-section">

                <job-pipeline-top-bar :job="job"></job-pipeline-top-bar>

                <job-pipeline-toggler :job="job"></job-pipeline-toggler>

                <JobPipelineLoader v-if="isContentLoading"></JobPipelineLoader>

                <div class="pipeline-box" v-else>

                    <pipeline-default-sidebar></pipeline-default-sidebar>

                    <job-candidate></job-candidate>

                </div>

            </section>

        </div>
    </dashboard-layout>
</template>

<script>
import {mapState, mapActions} from "vuex";
import client from "../../../app/api/Client";
import {SWITCH_TO_ACCOUNT, UPDATE_PAGE_STATE} from "../../../constants/action-type";
import {SWITCHED_TO_ANOTHER_ACCOUNT} from '../../../constants/events';
import {EventBus} from "../../../extra/event-bus";

const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const JobPipelineTopBar = () => import("../../../components/jobs/pipeline/PipelinePageTopBar");
const JobPipelineToggler = () => import("../../../components/jobs/pipeline/PipelinePageToggler");
const JobPipelineLoader = () => import("../../../components/_loaders/JobPipelineLoader");
const PipelineDefaultSidebar = () => import("../../../components/jobs/pipeline/DefaultViewSidebar");
const JobCandidate = () => import("../../../components/jobs/pipeline/DefaultViewCandidate");

export default {
    components: {
        DashboardLayout,
        JobPipelineTopBar,
        JobPipelineToggler,
        JobPipelineLoader,
        PipelineDefaultSidebar,
        JobCandidate
    },
    data() {
        return {
            isContentLoading: false,
            job: null,
        }
    },
    computed: {
        ...mapState(['company', 'user']),
    },
    methods: {
        ...mapActions([UPDATE_PAGE_STATE]),

        async getPipelineWithCandidate() {
            this.isContentLoading = true;
            try {

                let {data: {data: {pipelines, job, switchCompany, company_id, ai_score_show}}} = await client().get(`/job/${this.$route.params.jobId}/pipeline`);

                if (switchCompany) {
                    this.switchCompany(company_id);
                }

                this.job = job;
                this.UPDATE_PAGE_STATE({pipelines, ai_score_show});

            } catch (error) {
                this.$toast.error(this.$t(error.response.data.message));
                if (error.response.status === 480 || error.response.status === 490) {
                    await this.$router.push({name: 'jobs.published'});
                }
            }
            this.isContentLoading = false;
        },

        switchCompany(companyId) {
            if (companyId !== this.company.id) {
                let company = _.find(this.user.companies, {id: companyId});

                this[SWITCH_TO_ACCOUNT](company);

                EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, 'company');
            }
        },

        setDefaultPageState() {
            this[UPDATE_PAGE_STATE]({
                pipelines: [],
                selectedApplicant:[],
                activeStage: null,
                showOnBoardingModal: false,
                showRemoteInterviewModal: false,
                dragApplicantId: null,
                dragPipelineId: null,
                moveToPipeline: null,
                dropCandidate: true,
                defaultBoard: true,
                sortBy: '',
                candidatePipelineOrders: [],
                ai_score_show: false
            });
        }
    },

    async created() {
        this.setDefaultPageState();
        await this.getPipelineWithCandidate();
    }
}
</script>
